body {
  min-width: 100vw;
 
}
.App {
 
  text-align: center;
  display:flex;
  flex-direction: column;
  min-height: 100vh;
}
title {
  color:red;
  font-weight: bold;
}
.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
 
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: #222;
  height: 180px;
  padding: 20px;
  color: white;
  margin-bottom: 5px;
  gap: 50px;
}
.headerCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.headerInfo, .headerContacts {
  width: 300px;
  color:lightblue;
  display: flex;
  font-size: 14px;
  flex-direction: column;
  justify-content: left;
  align-items: flex-start;
  
}
.headerContacts {
  margin-left:20%;
}
.headerInfo {
  margin-right:10%;
}
.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
.cardDraw {
  width: 150px;
  height: 150px;
  /* border: 2px solid grey; */
  /* border-radius: 20px;
  background-color:lightgray;
  box-shadow: 2px 2px 1px 1px black; */
  
}
.cardDraw:hover {
  cursor:pointer;

  /* box-shadow: 1px 1px 1px 1px black; */
}

.spanStyle {
  z-index: 100;
}
.homeGoodsContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  gap:50px;
  

}

/* .active {
  font-weight: bold;
  border:2px solid black;
  text-decoration: none; 
}  */

.singleCardDraw {
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  /* align-self: center; */
  margin-top: auto;
  background-color: #222;
  color:antiquewhite;
}

/* .footerContacts span {
  display: block;
} */

.footerContacts  .footerEmails {
  display: flex;
  flex-direction: column;
  
}

.footerEmails div{
  display: flex;
  justify-content: left;
}

.mainFotoCards {
  width:100%;
  height: 100%;
  border-radius: 10px;
  transition:
    width 0.5s,
    height 0.5s,
}
.mainFotoCards:hover{
  width: 105%;
  height: 105%;
}
.navigatorBand {
  display:flex;
  flex-direction:row;
  justify-content: center;
  align-items:center;
  gap:4px;
  flex-wrap: wrap;
}
.button{
  font-size: 5px;
  text-decoration: none;
}
.inputBearingsPage {
  font-size: 30px;
  width:350px;
  margin-top: 5px;
  
}
.inputBearingsPageDisabled {
  font-size: 30px;
  width:350px;
  border: 1px solid lightgrey;
  opacity: 0.5;
}

.bearingsListBlock {
  display:flex;
  flex-direction:row;
  justify-content:space-between;
  align-self: center;
  align-items: center;
  background-color: white;
  width: 50%;
  min-height: 40px;
  
  margin:5px;
}
.bearingsListContainer {
  display:flex;
  flex-direction: column;
  justify-content: center;
}

.aboutUsWrapper {
  margin-top:10px;
  margin-bottom:10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.adressMapImg {
  width:400px;

}
.adressMapImgMaxim {
  width: 100vw;
}
.adressMapImg:hover{
  cursor: pointer;
}

.adressMapImgMaxim:hover{
  cursor: pointer;
}

@media screen and (max-width: 800px) and (min-width: 500px){
  .headerInfo {
    margin-left: 5px;
    width: 300px;
    
    font-size:small;
    
  }
  .headerContacts {
    margin-right: 5px;
    width: 250px;
    font-size:small;
    
  }

  .App-header {
    gap:10px;
  }
  .tovHeader {
    font-size: 25px;
    margin-top: 20px;
    
    
  }
}
@media screen and (max-width: 500px) {
  .bearingsListBlock {
    width:80%;
  }
  .headerInfo {
    margin-left: 5px;
    min-width: 200px;
    font-size:x-small;
  }
  .headerContacts {
    margin-right: 0px;
    padding-left: 70px;
    min-width: 180px;
    font-size:x-small;
  }

  .App-header {
    gap:0px;
  }
  .tovHeader {
    font-size: 20px;
    margin-top: 35px;
  }
  .adressMapImg {
    width:250px;
  }

}
.marqueeInfinite {
  /* margin:30px auto; */
  /* border:1px solid grey; */
  max-width: 100%;
  overflow: hidden;
  

}
.marqueeInfinite div {
  display: flex;
  width: 200%;
  animation: marqueeInfinite 30s linear infinite;
  justify-content: space-around;
}
.marqueeInfinite span{
  width:100%;
  display: flex;
}
.marqueeInfinite img{
  width: 50px;
  height: 25px;
  margin-left: 4px;
}
@keyframes marqueeInfinite {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-50%)
  }
}
